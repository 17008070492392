import React from "react";
// @ts-expect-error //lorem lorem lorem
import artChallengeLogo from "./art-challenge.png";

export const ArtChallengeLogo = () => {
    return (
        <>
            <a href="/">
                <img src={artChallengeLogo} alt="Logo" className="w-[77px] cursor-pointer"/>
            </a>
        </>
    );
};

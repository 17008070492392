export const icons = {
  header: {
    globe: require("./globe.png"),
    burger: require("./burger.png"),
    cross: require("./cross.png")
  },
  button: {
    arrow: require("./arrow.png"),
    play: require("./play.png"),
    close: require("./close.png"),
  },
  cards: {
    checkmark: require("./checkmark.png"),
  },
  perks:{
    block1: require("./block-1.png"),
    block2: require("./block-2.png"),
    block3: require("./block-3.png"),
    block4: require("./block-4.png"),
  },
  footer:{
      facebook: require("./facebook.png"),
      linkedIn: require("./linkedIn.png"),
      mail: require("./mail.png"),
      whatsApp: require("./whatsApp.png")
  }
};

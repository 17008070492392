import React, {useState} from "react";
import {HeroVid} from "../../assets/videos/hero/hero";
import {HeroHeader} from "./components/hero-header";

export const Hero: React.FC = () => {
    const [videoExpanded, setVideoExpanded] = useState<boolean>(false);


    return (
        <div id='hero' className="grid">
            <HeroVid collapseVideo={() => setVideoExpanded(false)} videoExpanded={videoExpanded} />
            <div className="py-48 md:py-60" style={{ opacity: videoExpanded ? 0 : 1, transition: "opacity 600ms", gridColumnStart: 1, gridRowStart: 1, zIndex: 2 }}>
                <HeroHeader onPlayButtonClick={() => setVideoExpanded(true)} fadeOut={videoExpanded} />
            </div>
        </div>
    );
};


import React from "react";

interface CardListProps {
  item1: string;
  item2: string;
  item3: string;
  item4: string;
}

export const CardList: React.FC<CardListProps> = ({ item1, item2, item3, item4 }) => {
  const items = [item1, item2, item3, item4];

  return (
    <div className='mb-8 text-heading-7' >
      <ul className="px-[5%] md:px-0" style={{ listStyle: 'disc' }}>
        {items.map((item, index) => (
          <li key={index} className="flex items-center mb-4">
            {/* <span className="h-2 w-2 bg-white rounded-full mr-2"></span> */}
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

import React from "react";
import {useTranslation} from "react-i18next";

export const Header = () => {
    const { t } = useTranslation();

    return (
        <header className="text-white text-center leading-6 mt-10 md:mt-20 lg:mt-30">
            <div className="leading-primary text-heading-6 md:text-heading-5 font-montserrat font-bold mb-[20px] md:leading-7">
                {t("pricing.header.header")}
            </div>
            <div className="font-montserrat text-heading-7 text-primary-gray-200 px-[40px] max-w-156">
                {t("pricing.header.subHeaderFirstLine")} {t("pricing.header.subHeaderSecondLine")}
            </div>
        </header>
    );
};
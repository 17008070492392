import React from "react";

interface BlockProps {
    icon: string;
    header: string;
    description: string;

}

export const Block: React.FC<BlockProps> = ({icon, header, description}) => {
    return (
        <div className="max-w-[370px] font-montserrat">
            <div className="flex flex-row mb-8 items-center">
                <img src={icon} alt="icon" style={{width: '24px', height: '24px'}}/>
                <div className='pl-3 text-heading-6 text-primary-white-0'>{header}</div>
            </div>
            <div className='text-heading-8 max-w-80 text-primary-white-0'>{description}</div>
        </div>
    );
}
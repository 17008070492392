import React from "react";
import { ArtChallengeLogo } from "../../assets/images/common/art-main-logo/art-challenge-logo";
import { LangSelector } from "./components/lang-selector/lang-selector";
import { Navigation } from "./components/navigation";

interface HeaderProps {
    hideNavigation?: boolean
}

export const Header: React.FC<HeaderProps> = ({ hideNavigation }) => {

    return (
        <header className="z-40 h-[10vh] w-screen bg-black flex flex-row justify-between items-center px-[40px]">
            <ArtChallengeLogo />
            <div className="hidden md:hidden lg:block xl:block">
                {!hideNavigation && <Navigation />}
            </div>
            <LangSelector />
        </header>
    );
};

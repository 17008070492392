import React from "react";
import { Header } from "./components/header";
import { PremiumCard } from "./cards/premium-card/premium-card";
import { BasicCard } from "./cards/basic-card/basic-card";
import { StandardCard } from "./cards/standard-card/standard-card";
import { Button } from "../../global-components/button/button";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';


export const Pricing = () => {

    const navigate = useNavigate();
    const handleRedirect = () => {
        navigate('/contact-us-page');
        window.scrollTo(0, 0);
    };
    const { t } = useTranslation();
    return (
        <div id='pricing' className='items-center flex flex-col'>
            <Header />
            <div className='flex justify-center items-stretch mt-0 sm:mt-[34px] md:mt-[44px] px-4 md:px-5 py-8 w-full flex-col md:flex-row gap-y-8 max-w-200'>
                <BasicCard />
                <StandardCard />
                <PremiumCard />
            </div>
            <div className='mt-8 md:mt-19 lg:mt-23'>
                <Button variant={"outlined"} size={"large"} onClick={handleRedirect} title={t("pricing.buttonText")} buttonBold={t("pricing.buttonBold")} forwardIcon />
            </div>
        </div>
    );
}
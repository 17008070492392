import React, { useState, useEffect } from "react";
import { CardHeader } from "../../components/card-header";
import { CardList } from "../../components/card-list";
import { CardGradientLine } from "../../components/card-gradient-line";
import { CardCustomList } from "../../components/card-custom-list";
import { Button } from "../../../../global-components/button/button";
import "../sub-cards.css";
import { useTranslation } from "react-i18next";

export const StandardCard = () => {
    const { t } = useTranslation();
    const [showDetails, setShowDetails] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setShowDetails(window.innerWidth >= 640);
        };
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleButtonClick = () => {
        setShowDetails(!showDetails);
    };

    const openURL = () => {
        window.location.href = window.location.origin + '/checkout/?add-to-cart=14&quantity=1';
    }

    return (
        <div className="text-white relative flex flex-col justify-between z-[1]
        sub-card-container basic-border-gradient-bw basic-card-diffs h-auto px-5 xl:px-17
        transition-transform md:hover:backdrop-brightness-0 md:hover:scale-[1.084] hover:z-10
        descale-siblings-on-hover order-2
        ">

            <div className='flex flex-col'>
                <CardHeader title={t("pricing.cards.standard.title")} price={t("pricing.cards.standard.price")}/>

                <span
                    className='font-montserrat border-gradient-bw xl:self-start
                    self-center headerSpan content-center justify-center items-center w-[90%]
                    md:text-[14px] py-2 descale-siblings-on-hover'>{t("pricing.cards.standard.subHeader")}</span>

                {showDetails && <div className='mt-[24px]'> <CardList
                    item1={t("pricing.cards.premium.list.item1")}
                    item2={t("pricing.cards.premium.list.item2")}
                    item3={t("pricing.cards.premium.list.item3")}
                    item4={t("pricing.cards.premium.list.item4")}
                /> </div>}
                {showDetails && <div className='mr-[24px] ml-[24px]'> <CardGradientLine/> </div>}
                {showDetails && <div className='ml-[24px]'> <CardCustomList/> </div>}
            </div>
            <div className='mb-8 items-center h-[53px] self-center mx-auto w-[90%] xl:mr-[80px] mt-[24px] text-[18px] bg-primary-purple-700 md:bg-transparent md:border md:border-white
            '>
                <Button variant={"card-outlined"} size={"large"} onClick={openURL} title={t("pricing.cardButtonText")} buttonBold={''} />
            </div>
            <div className='self-center text-heading-7 mb-8
            sm:hidden
            ' onClick={handleButtonClick}>
                {showDetails ? t("pricing.lessInfoButton") : t("pricing.moreInfoButton")}
            </div>
        </div>
    )
}
import React, {useEffect, useState} from "react";
import {CardHeader} from "../../components/card-header";
import {CardList} from "../../components/card-list";
import {CardCustomList} from "../../components/card-custom-list"; // Import statement is present
import {Button} from "../../../../global-components/button/button";
import {CardGradientLine} from "../../components/card-gradient-line";
import {useTranslation} from "react-i18next";
import "./premium-card.css"

interface CardDetailsProps {
    t: (key: string) => string;
}

const CardDetails: React.FC<CardDetailsProps> = ({ t }: CardDetailsProps) => {
    return (<>
        <div className='mt-6'>
            <CardList
                item1={t("pricing.cards.premium.list.item1")}
                item2={t("pricing.cards.premium.list.item2")}
                item3={t("pricing.cards.premium.list.item3")}
                item4={t("pricing.cards.premium.list.item4")}
            />
        </div>
        <CardGradientLine color />
        <div className='mt-4 mb-4'>{t("pricing.cards.premium.feat")}</div>
        <CardGradientLine color />
        <CardCustomList />
    </>)
}

export const PremiumCard = () => {
    const {t} = useTranslation()
    const [showDetails, setShowDetails] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setShowDetails(window.innerWidth >= 640);
        };

        // Initial check on component mount
        handleResize();

        window.addEventListener("resize", handleResize);

        // Cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleButtonClick = () => {
        setShowDetails(!showDetails);
    };

    const openURL = () => {
        window.location.href = window.location.origin + '/checkout/?add-to-cart=15&quantity=1';
    }

    return (
        <div className="premium-card-container border-gradient-purple justify-between overflow-hidden
         text-white relative flex flex-col z-[2]
         md:px-[24px] xl:px-17 order-1
         transition-transform md:backdrop-brightness-0 md:scale-[1.084] hover:z-10

         ">
            <div className="z-20 px-6 md:px-0">
                <CardHeader title={t("pricing.cards.premium.title")} price={t("pricing.cards.premium.price")}/>

                <span className='font-montserrat border-gradient-bw self-center md:text-[14px] py-2 headerSpan content-center justify-center items-center w-[100%] mx-auto md:mb-[10px] xl:mb-[20px]'>{t("pricing.cards.premium.subHeader")}</span>
                {showDetails && <CardDetails t={t} />}
            </div>
            <div className='w-[100%] h-[53px] mt-6 mb-8 px-6 md:px-0 self-center z-30'>
                <Button variant={"filled"} size={"large"} onClick={openURL} title={t("pricing.cardButtonText")} buttonBold={''} />
            </div>
            <div className='self-center text-heading-7 mb-8 z-[4]  px-6 md:px-0 sm:hidden cursor-pointer' onClick={handleButtonClick}>
                {showDetails ? t("pricing.lessInfoButton") : t("pricing.moreInfoButton")}
            </div>
        </div>
    );
};

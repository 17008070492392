import React from "react";

import {Header} from "./components/header";
import {Logos} from "./components/logos/logos";

export const Trusted = () => {

    return (
        <div id='trusted' className="w-full flex flex-col justify-center items-center">
            <Header/>
            <Logos/>
        </div>
    );
}
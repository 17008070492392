import React from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import "./carousel.css";
import { carouselItems } from "../../assets/images/carousel/carousel";
import { Button } from "../../global-components/button/button";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "usehooks-ts";

interface CarouselSlideProps {
    imageSrc: string;
    altText: string;
    buttonTitle: string;
}

const CarouselSlide: React.FC<CarouselSlideProps> = ({ imageSrc, altText, buttonTitle }) => (
    <div className="keen-slider__slide relative min-w-180">
        <img src={imageSrc} alt={altText} className="" />
        <div className="absolute right-6 bottom-4 mt-5">
            <Button
                variant="filled"
                size="medium"
                title={buttonTitle}
                forwardIcon
                buttonBold=""
                shadow
            />
        </div>
    </div>
);

const CarouselDesktop: React.FC = () => {
    const { t } = useTranslation();
    const [sliderRef] = useKeenSlider({
        slides: { perView: 'auto', spacing: 32, origin: "center" },
        initial: 1,
        mode: "free",
        loop: true,
    });

    return (
        <div id="gallery" ref={sliderRef} className="keen-slider mt-30">
            {Object.entries(carouselItems).map(([key, value]) => (
                <CarouselSlide
                    key={key}
                    imageSrc={value}
                    altText={key}
                    buttonTitle={t("carousel.button")}
                />
            ))}
        </div>
    );
};

const CarouselMobile: React.FC = () => {
    const [sliderRef, instanceRef] = useKeenSlider({
        slides: { perView: 1, spacing: 0, origin: "center" },
        initial: 1,
        loop: true,
    });

    return (
        <div className="slider-container">
            <div id="gallery" ref={sliderRef} className="keen-slider [&>div]:h-fit [&>div]:my-auto">
                {Object.entries(carouselItems).map(([key, value]) => (
                    <div className="keen-slider__slide" key={key}>
                        <img src={value} alt={key} />
                    </div>
                ))}
            </div>
            <div className="flex justify-center space-x-[20px] mt-2">
                <div className="arrow-left" onClick={() => instanceRef.current?.prev()}>
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className="fill-white" d="M22 13.1387C22.5523 13.1387 23 12.691 23 12.1387C23 11.5864 22.5523 11.1387 22 11.1387L22 13.1387ZM1.29289 11.4316C0.902369 11.8221 0.902369 12.4553 1.29289 12.8458L7.65685 19.2097C8.04738 19.6003 8.68054 19.6003 9.07107 19.2097C9.46159 18.8192 9.46159 18.1861 9.07107 17.7955L3.41421 12.1387L9.07107 6.48182C9.46159 6.09129 9.46159 5.45813 9.07107 5.0676C8.68054 4.67708 8.04738 4.67708 7.65685 5.0676L1.29289 11.4316ZM22 11.1387L2 11.1387L2 13.1387L22 13.1387L22 11.1387Z" />
                    </svg>
                </div>
                <div className="arrow-right" onClick={() => instanceRef.current?.next()}>
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 11.1387C1.44772 11.1387 1 11.5864 1 12.1387C1 12.691 1.44772 13.1387 2 13.1387L2 11.1387ZM22.7071 12.8458C23.0976 12.4553 23.0976 11.8221 22.7071 11.4316L16.3431 5.0676C15.9526 4.67708 15.3195 4.67708 14.9289 5.0676C14.5384 5.45813 14.5384 6.09129 14.9289 6.48182L20.5858 12.1387L14.9289 17.7955C14.5384 18.186 14.5384 18.8192 14.9289 19.2097C15.3195 19.6003 15.9526 19.6003 16.3431 19.2097L22.7071 12.8458ZM2 13.1387L22 13.1387L22 11.1387L2 11.1387L2 13.1387Z" fill="white" />
                    </svg>
                </div>
            </div>
        </div>
    );
};

export const Carousel: React.FC = () => {
    const { width = 0 } = useWindowSize();

    return width >= 820 ? <CarouselDesktop /> : <CarouselMobile />;
};

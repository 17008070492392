import React, { useState, useEffect } from "react";
import { HeaderNavButton } from "./nav-button/header-nav-button";
import { useTranslation } from "react-i18next";

export const Navigation: React.FC = () => {
    const { t } = useTranslation();

    const navItems = [
        { title: "header.home", link: "/#hero" },
        { title: "header.trusted", link: "/#trusted" },
        { title: "header.gallery", link: "/#gallery" },
        { title: "header.prices", link: "/#pricing" },
        { title: "header.otherServices", link: "/#otherServices" },
        { title: "header.benefits", link: "/#benefits" },
        { title: "header.faq", link: "/#faq" },
        { title: "header.contactUs", link: "/contact-us-page" },
    ];

    const [activeLink, setActiveLink] = useState("");

    useEffect(() => {
        const updateActiveLink = () => {
            const currentPath = window.location.pathname + window.location.hash;
            setActiveLink(currentPath);
        };

        updateActiveLink();

        window.addEventListener("popstate", updateActiveLink);
        window.addEventListener("hashchange", updateActiveLink);

        return () => {
            window.removeEventListener("popstate", updateActiveLink);
            window.removeEventListener("hashchange", updateActiveLink);
        };
    }, []);

    return (
        <nav>
            {navItems.map((item, index) => (
                <HeaderNavButton
                    key={index}
                    title={t(item.title)}
                    link={item.link}
                    isActive={activeLink === item.link}
                    setActiveLink={setActiveLink}
                />
            ))}
        </nav>
    );
};

import React, {useEffect} from 'react';
import ScrollMagic from 'scrollmagic';
import {useTranslation} from "react-i18next";
import "../strips.css"

export const MarqueeComponent: React.FC = () => {
    useEffect(() => {
        const sm = new ScrollMagic.Controller({refreshInterval: 0});
        const marquee = document.querySelector('.marquee') as HTMLElement;

        const scene = new ScrollMagic.Scene({
            triggerElement: marquee,
            triggerHook: 'onEnter',
            offset: -200,
            duration: window.innerHeight + marquee.scrollWidth * 1.8,
        });

        // @ts-expect-error //lorem lorem lorem
        scene.on('progress', (event: { progress: number }) => {
            const {progress} = event;
            const amount = -marquee.scrollWidth;
            const startingOffset = -window.innerWidth / 4;
            marquee.style.transform = `translate3d(0, 0, 0) translateX(${amount * progress + startingOffset}px)`;
        });

        scene.addTo(sm);

        return () => {
            sm.destroy(true);
        };
    }, []);

    const {t} = useTranslation();

    const marqueeItems = [
        { tag: t("benefits.strips.consultation"), dotColor: 'bg-primary-accent-info' },
        { tag: t("benefits.strips.server"), dotColor: 'bg-primary-accent-error' },
        { tag: t("benefits.strips.api"), dotColor: 'bg-primary-accent-3' },
        { tag: t("benefits.strips.appDesign"), dotColor: 'bg-primary-accent-info' },
        { tag: t("benefits.strips.development"), dotColor: 'bg-primary-accent-3' },
        { tag: t("benefits.strips.webDesign"), dotColor: 'bg-primary-accent-error' },
        { tag: t("benefits.strips.seo"), dotColor: 'bg-primary-accent-info' },
        { tag: t("benefits.strips.mobile"), dotColor: 'bg-primary-accent-3' },
        { tag: t("benefits.strips.consultation"), dotColor: 'bg-primary-accent-info' },
        { tag: t("benefits.strips.appDesign"), dotColor: 'bg-primary-accent-error' },
        { tag: t("benefits.strips.development"), dotColor: 'bg-primary-accent-3' },
        { tag: t("benefits.strips.server"), dotColor: 'bg-primary-accent-info' },
        { tag: t("benefits.strips.consultation"), dotColor: 'bg-primary-accent-info' },
        { tag: t("benefits.strips.server"), dotColor: 'bg-primary-accent-error' },
        { tag: t("benefits.strips.api"), dotColor: 'bg-primary-accent-3' },
        { tag: t("benefits.strips.appDesign"), dotColor: 'bg-primary-accent-info' },
        { tag: t("benefits.strips.development"), dotColor: 'bg-primary-accent-3' },
        { tag: t("benefits.strips.webDesign"), dotColor: 'bg-primary-accent-error' },
        { tag: t("benefits.strips.seo"), dotColor: 'bg-primary-accent-info' },
        { tag: t("benefits.strips.mobile"), dotColor: 'bg-primary-accent-3' },
        { tag: t("benefits.strips.consultation"), dotColor: 'bg-primary-accent-info' },
        { tag: t("benefits.strips.appDesign"), dotColor: 'bg-primary-accent-error' },
        { tag: t("benefits.strips.development"), dotColor: 'bg-primary-accent-3' },
        { tag: t("benefits.strips.server"), dotColor: 'bg-primary-accent-info' }
    ];

    return (
        <div className='text-white'>
            <section>
                <div className="regular-marquee-wrapper">
                    <div className="marquee" style={{display: 'flex'}}>
                        {marqueeItems.map((item, index) => (
                            <React.Fragment key={index}>
                                <div className="Marquee-tag">{item.tag}</div>
                                <div className={`Marquee-dot w-[20px] h-[20px] rounded-2xl ${item.dotColor}`}></div>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

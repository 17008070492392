import React from "react";
import {MarqueeComponent} from "./regular-strip/marquee-comp";
import {FadedMarqueeComponent} from "./faded-strip/faded-marquee-comp";

export const Strips = () => {
    return (
        <div className="strips-container h-[350px] ">
            <MarqueeComponent/>
            <FadedMarqueeComponent/>
        </div>
    );
};

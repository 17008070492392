import React from "react";
import {useTranslation} from "react-i18next";

export const Header = () => {
    const {t} = useTranslation();

    return (
        <header className="flex flex-col items-center w-full text-center">
            <div className="leading-primary text-heading-6 md:text-heading-5 font-montserrat font-bold text-white pb-4 mt-8">
                {t("faq.header.header")}
            </div>
            <div className="font-montserrat leading-normal text-primary-gray-200 mb-8 md:mb-14 max-w-120">
                {t("faq.header.subHeaderfl")} {t("faq.header.subHeadersl")}
            </div>
        </header>
    );
};

import React, { useEffect, useState } from "react";
import { CardHeader } from "../../components/card-header";
import { CardList } from "../../components/card-list";
import { CardGradientLine } from "../../components/card-gradient-line";
import { Button } from "../../../../global-components/button/button";
import "../sub-cards.css";
// import "./premium-card.css"

import { useTranslation } from "react-i18next";

export const BasicCard = () => {
    const { t } = useTranslation();
    const [showDetails, setShowDetails] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setShowDetails(window.innerWidth >= 640);
        };

        // Initial check on component mount
        handleResize();

        window.addEventListener("resize", handleResize);

        // Cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleButtonClick = () => {
        setShowDetails(!showDetails);
    };

    const openURL = () => {
        window.location.href = window.location.origin + '/checkout/?add-to-cart=13&quantity=1';
    }

    return (
        <div className="text-white relative flex flex-col justify-between z-[1]
            sub-card-container basic-border-gradient-bw basic-card-diffs px-5 xl:px-17 h-auto
            font-lato transition-transform md:hover:backdrop-brightness-0 hover:z-10
            md:hover:scale-[1.084] descale-siblings-on-hover 
        ">
            <div className='flex flex-col'>
                <CardHeader title={t("pricing.cards.basic.title")} price={t("pricing.cards.basic.price")} />
                <span className='font-montserrat border-gradient-bw md:text-[14px] py-2 headerSpan content-center justify-center items-center w-[90%] xl:self-start
                    self-center'>{t("pricing.cards.basic.subHeader")}</span>
                {showDetails && <div className='mt-[24px]'> <CardList
                    item1={t("pricing.cards.premium.list.item1")}
                    item2={t("pricing.cards.premium.list.item2")}
                    item3={t("pricing.cards.premium.list.item3")}
                    item4={t("pricing.cards.premium.list.item4")}
                /> </div>}
                {showDetails && <div className='mr-[24px] ml-[24px]'> <CardGradientLine /> </div>}
            </div>
            <div className='mb-8 items-center h-[53px] self-center mx-auto w-[90%] xl:mr-[80px] mt-[24px] text-[18px] bg-primary-purple-700 md:bg-transparent md:border md:border-white'>
                <Button variant={"card-outlined"} size={"large"} onClick={openURL} title={t("pricing.cardButtonText")} buttonBold={''} />
            </div>
            <div className='self-center text-heading-7 mb-8 sm:hidden' onClick={handleButtonClick}>
                {showDetails ? t("pricing.lessInfoButton") : t("pricing.moreInfoButton")}
            </div>
        </div>
    )
}

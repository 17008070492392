import React, { useRef } from "react";
import { Header } from "../header/header";
import { Footer } from "../footer/footer";
import { artColorLogo } from "../../assets/images/common/art-logo-variants/art-big-logos";
import { bgImages } from "../../assets/images/bg-images";
import './contact-us.css'
import { useTranslation } from "react-i18next";
import { Form, Input, Select, Button } from 'antd';
const { Option } = Select;
// import emailjs from '@emailjs/browser';

interface FormSubmitResult {
    full_name: string,
    email: string,
    company_name: string,
    website: string,
    business_category: string,
    current_role: string,
    company_size: string,
    description: string,
}

interface FormStatusMessages {
    success: string,
    error: string
}

const sendEmail = async (form: FormSubmitResult, messages: FormStatusMessages) => {
    const data = {
        service_id: 'service_oc9flmi',
        template_id: 'template_kludma6',
        user_id: 'dYIb8V-MtbAEnSGem',
        template_params: form,
    };

    try {

        const response = await fetch('https://api.emailjs.com/api/v1.0/email/send', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (response.ok) {
            alert(messages.success);
        } else {
            const errorData = await response.json();
            console.error(messages.error);
            console.error(errorData);
        }
    } catch (error: unknown) {
        console.error(messages.error);
        console.error(error);
    }
};

const ContactForm: React.FC = () => {
    const { t } = useTranslation();
    const formRef = useRef();

    const inputFields = [
        { title: t('contactUsPage.form.inputFields.f1.title') },
        { title: t('contactUsPage.form.inputFields.f2.title') },
        { title: t('contactUsPage.form.inputFields.f3.title') },
        { title: t('contactUsPage.form.inputFields.f4.title') },
        { title: t('contactUsPage.form.inputFields.f5.title') },
    ];

    const dropdowns = [
        {
            title: t('contactUsPage.form.dropdowns.d1.title'),
            option1: t('contactUsPage.form.dropdowns.d1.option1'),
            option2: t('contactUsPage.form.dropdowns.d1.option2'),
            option3: t('contactUsPage.form.dropdowns.d1.option3'),
            option4: t('contactUsPage.form.dropdowns.d1.option4'),
        },
        {
            title: t('contactUsPage.form.dropdowns.d2.title'),
            option1: t('contactUsPage.form.dropdowns.d2.option1'),
            option2: t('contactUsPage.form.dropdowns.d2.option2'),
            option3: t('contactUsPage.form.dropdowns.d2.option3'),
            option4: t('contactUsPage.form.dropdowns.d2.option4'),
        },
        {
            title: t('contactUsPage.form.dropdowns.d3.title'),
            option1: t('contactUsPage.form.dropdowns.d3.option1'),
            option2: t('contactUsPage.form.dropdowns.d3.option2'),
            option3: t('contactUsPage.form.dropdowns.d3.option3'),
            option4: t('contactUsPage.form.dropdowns.d3.option4'),
        },
    ];


    const onFinish = async (formRef: FormSubmitResult) => {
        console.log('Success:', formRef);
        await sendEmail(formRef, { success: t('contactUsPage.form.mail_success'), error: t('contactUsPage.form.mail_error') })
    };

    const onFinishFailed = (errorInfo: unknown) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form
            ref={formRef.current}
            name="contact-form"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            style={{}}
        >
            <div className="flex gap-x-6 lg:gap-x-15">
                <Form.Item
                    name="full_name"
                    rules={[{ required: true, message: 'Please input your full name!' }]}
                    style={{ flex: 1, }}
                >
                    <Input placeholder={inputFields[0].title} style={{ borderColor: '#8a2be2', margin: 'auto' }} />
                </Form.Item>

                <Form.Item
                    name="email"
                    rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}
                    style={{ flex: 1 }}
                >
                    <Input placeholder={inputFields[1].title} style={{ borderColor: '#8a2be2' }} />
                </Form.Item>
            </div>

            <div className="flex gap-x-6 lg:gap-x-15">
                <Form.Item
                    name="company_name"
                    rules={[{ required: false, message: 'Please input your company name!' }]}
                    style={{ flex: 1 }}
                >
                    <Input placeholder={inputFields[2].title} style={{ borderColor: '#8a2be2' }} />
                </Form.Item>

                <Form.Item
                    name="website"
                    rules={[{ required: false }]}
                    style={{ flex: 1 }}
                >
                    <Input placeholder={inputFields[3].title} style={{ borderColor: '#8a2be2' }} />
                </Form.Item>
            </div>

            <Form.Item
                name="business_category"
                rules={[{ required: false, message: 'Please select a business category!' }]}
            >
                <Select placeholder={dropdowns[0].title} style={{ borderColor: '#8a2be2' }}>
                    <Option value="tech">{dropdowns[0].option1}</Option>
                    <Option value="finance">{dropdowns[0].option2}</Option>
                    <Option value="healthcare">{dropdowns[0].option3}</Option>
                    <Option value="education">{dropdowns[0].option4}</Option>
                </Select>
            </Form.Item>

            <Form.Item
                name="current_role"
                rules={[{ required: false, message: 'Please select your current role!' }]}
            >
                <Select placeholder={dropdowns[1].title} style={{ borderColor: '#8a2be2' }}>
                    <Option value="ceo">{dropdowns[1].option1}</Option>
                    <Option value="cto">{dropdowns[1].option2}</Option>
                    <Option value="developer">{dropdowns[1].option3}</Option>
                    <Option value="designer">{dropdowns[1].option4}</Option>
                </Select>
            </Form.Item>

            <Form.Item
                name="company_size"
                rules={[{ required: false, message: 'Please select your company size!' }]}
            >
                <Select placeholder={dropdowns[2].title} style={{ borderColor: '#8a2be2' }}>
                    <Option value="small">{dropdowns[2].option1}</Option>
                    <Option value="medium">{dropdowns[2].option2}</Option>
                    <Option value="large">{dropdowns[2].option3}</Option>
                    <Option value="enterprise">{dropdowns[2].option4}</Option>
                </Select>
            </Form.Item>

            <Form.Item
                name="description"
                rules={[{ required: true, message: 'Please input a description!' }]}
            >
                <Input.TextArea placeholder={inputFields[4].title} rows={1} style={{ borderColor: '#8a2be2' }} />
            </Form.Item>

            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                        backgroundColor: '#8a2be2',
                        borderColor: '#8a2be2',
                        width: '100%',
                        color: '#fff',
                    }}
                >{t('contactUsPage.form.buttonText')}</Button>
            </Form.Item>
        </Form>
    );
};


export const ContactUsPage: React.FC = () => {
    const { t } = useTranslation();
    return (
        <div className='bg-black text-white w-[100%] overflow-x-hidden font-lato'>
            <Header hideNavigation />
            <main className='w-[100%] grid justify-center md:grid-cols-[1fr] p-4 md:p-10 max-w-[1170px] mx-auto mt-[24px]'>
                <aside
                    className='md:col-start-1 md:row-start-1 z-10 h-fit md:max-w-[50%] lg:max-w-[45%] flex justify-center items-center h-full'
                >
                    <section
                        className='flex flex-col items-center text-center gap-[30px] py-[80px] px-[40px] leading-6 shadow-form-aside _md:mt-30 md:pt-28 md:pb-40 mb-[24px] md:mb-0'
                        style={{
                            backgroundColor: '#1D1C1E',
                            backgroundImage: `url(${bgImages.contactUsPageAside})`,
                            backgroundSize: 'cover',
                        }}
                    >
                        <img
                            src={artColorLogo.color}
                            alt={'Logo'}
                            className='w-[150px] relative'
                        />
                        <div className='text-heading-6'>
                            {t('contactUsPage.aside.header')}
                        </div>
                        <div className="text-shark-400">{t('contactUsPage.aside.subHeader')}</div>
                    </section>
                </aside>
                <article
                    className='p-[16px] md:p-[24px] lg:pr-[70px] w-full mx-auto md:col-start-1 md:row-start-1 md:max-w-[70%] md:pl-[23%] md:mr-0'
                    style={{
                        background:
                            'linear-gradient(136.5deg, #2E2D2E -36.18%, #0B0A0E 83.23%)',
                    }}
                >
                    <h1 className='text-heading-5 pt-[20px] font-bold mx-auto leading-primary mb-6'>
                        {t('contactUsPage.form.header')}
                    </h1>
                    <ContactForm />
                </article>
            </main>
            <Footer />
        </div>
    );

};
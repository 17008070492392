import React, {useEffect, useRef, useState} from 'react';
import "./panel.css"

interface PanelProps {
    label: string;
    content: string;
    activeTab: number;
    index: number;
    activateTab: (index: number) => void;
}

export const Panel: React.FC<PanelProps> = ({label, content, activeTab, index, activateTab}) => {
    const [height, setHeight] = useState<number | null>(null);
    const isActive = activeTab === index;
    const panelInnerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (panelInnerRef.current) {
            setHeight(isActive ? panelInnerRef.current.scrollHeight : 0);
        }
    }, [isActive]);

    const togglePanel = () => {
        if (isActive) {
            activateTab(-1); // Close panel if already active
        } else {
            activateTab(index); // Open panel if not active
        }
    };

    const innerStyle = {
        height: `${height}px`,
    };

    return (
        <div className='panel' role='tabpanel' aria-expanded={isActive}>
            <button className='panel__label' role='tab' onClick={togglePanel}>
                {label}
            </button>
            <div ref={panelInnerRef} className='panel__inner' style={innerStyle} aria-hidden={!isActive}>
                <p className='panel__content' onClick={togglePanel}>
                    {content}
                </p>
            </div>
        </div>
    );
};

import React, { useState } from "react";
import { logos } from "../../../../assets/images/logos/logos";
import { Logo } from "./logo";

export const Logos = () => {
    const [showMore, setShowMore] = useState(false);
    const logoKeys = Object.keys(logos) as Array<keyof typeof logos>;
    const initialLogosToShow = window.innerWidth < 600 ? 4 : logoKeys.length;

    const handleShowMore = () => {
        setShowMore(true);
    };

    const handleShowLess = () => {
        setShowMore(false);
    };

    return (
        <div className="h-full w-[70vw] flex flex-col justify-center items-center underline
        grid grid-cols-2 md:grid-cols-5 mt-[54px] gap-[54px]
        ">
            {logoKeys.slice(0, showMore ? logoKeys.length : initialLogosToShow).map((logoKey, index) => (
                <Logo key={index} logoKey={logoKey} />
            ))}
            {window.innerWidth < 600 && !showMore && (
                <button onClick={handleShowMore}>View More</button>
            )}
            {showMore && (
                <div className='flex'>
                    <button onClick={handleShowLess}>View Less</button>
                </div>
            )}
        </div>
    );
};

import React, { useState, useEffect } from 'react';

import { Trusted } from "./sections/trusted/trusted";
import { Benefits } from "./sections/benefits/benefits";
import { Hero } from "./sections/hero/hero";
import { Carousel } from "./sections/carousel/carousel";
import { Pricing } from "./sections/pricing/pricing";
import { Perks } from "./sections/perks/perks";
import { Faq } from "./sections/faq/faq";
import { ArtChallenge } from "./sections/art-chellenge/art-chellenge";
import { ContactUs } from "./sections/contact-us/contact-us";
import { Footer } from "./sections/footer/footer";
import { LangSelector } from "./sections/header/components/lang-selector/lang-selector";
import { Navigation } from "./sections/header/components/navigation";
import { ArtChallengeLogo } from './assets/images/common/art-main-logo/art-challenge-logo';
import { useTranslation } from "react-i18next";
import { HeaderNavButton } from './sections/header/components/nav-button/header-nav-button';

import './index.css'
import './global-components/side-menu/side-menu.css'

import { icons } from "./assets/icons/icons";

const App: React.FC = () => {
    const [isActiveNav, setIsActiveNav] = useState<boolean>(true);
    const [activeLink, setActiveLink] = useState<string>('');

    const toggleMenu = () => {
        const windowHeight = window.innerHeight;
        if (isActiveNav) {
            document.body.classList.remove('active-nav');
            document.querySelector('.page-wrap')?.setAttribute('style', 'height: auto');
        } else {
            document.body.classList.add('active-nav');
            document.querySelector('.page-wrap')?.setAttribute('style', `height: ${windowHeight}px`);
        }
        setIsActiveNav(!isActiveNav);
    };

    const { t } = useTranslation();

    const navItems = [
        { title: "header.home", link: "/#hero" },
        { title: "header.trusted", link: "/#trusted" },
        { title: "header.gallery", link: "/#gallery" },
        { title: "header.prices", link: "/#pricing" },
        { title: "header.otherServices", link: "/#otherServices" },
        { title: "header.benefits", link: "/#benefits" },
        { title: "header.faq", link: "/#faq" },
        { title: "header.contactUs", link: "/contact-us-page" }
    ];

    useEffect(() => {
        const updateActiveLink = () => {
            const currentPath = window.location.pathname + window.location.hash;
            setActiveLink(currentPath);
        };

        updateActiveLink();

        window.addEventListener('popstate', updateActiveLink);
        window.addEventListener('hashchange', updateActiveLink);

        return () => {
            window.removeEventListener('popstate', updateActiveLink);
            window.removeEventListener('hashchange', updateActiveLink);
        };
    }, []);

    return (
        <div>
            <aside className="under-nav lg:hidden">
                <nav className="mobile-nav gap-[40px]">
                    <div className='absolute top-[-170px]'>
                        <ArtChallengeLogo />
                        <img
                            className='fixed top-[30px] right-[30px] h-[40px] w-[40px]'
                            src={icons.header.cross}
                            alt="close"
                            onClick={toggleMenu}
                        />
                    </div>

                    {navItems.map((item, index) => (
                        <HeaderNavButton
                            key={index}
                            title={t(item.title)}
                            link={item.link}
                          isActive={activeLink === item.link}
                          setActiveLink={setActiveLink}
                          toggleMenu={toggleMenu}
                      />
                  ))}
                </nav>
            </aside>

            <div className="page-wrap">
                <header className="z-40 w-screen bg-black fixed">
                    <div className='w-full max-w-292.5 mx-auto px-4 md:px-14 h-[92px] flex flex-row items-center justify-between'>
                        <ArtChallengeLogo />
                        <div className="hidden md:hidden lg:block xl:block">
                            <Navigation />
                        </div>
                        <div className='gap-2 flex flex-row'>
                            <LangSelector />
                            <div className='visible lg:hidden'>
                                <img
                                    className="toggleMenu"
                                    onClick={toggleMenu}
                                    alt={'nav'}
                                    src={icons.header.burger}
                                />
                            </div>
                        </div>
                    </div>
                </header>
                <main className="main h-full w-full bg-black overflow-hidden text-white font-fontPublic relative">
                    <Hero />
                    <Trusted />
                    <Carousel />
                    <Pricing />
                    <Benefits />
                    <Perks />
                    <Faq />
                    <ArtChallenge />
                    <ContactUs />
                    <Footer />
                </main>
            </div>
        </div>
    );
};

export default App;

import React from "react";
import { Accordion } from "./components/accordion/accordion";
import { Header } from "./components/accordion/header";
import { Button } from "../../global-components/button/button";
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom";
// import faqBgImage from "../../assets/images/common/bg-images/faq-bg.png";

export const Faq = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleRedirect = () => {
        navigate('/contact-us-page');
        window.scrollTo(0, 0);
    };

    return (
        <div
            id='faq'
            className='flex flex-col items-center px-4'
        >
            <Header />
            <div className='flex flex-col'>
                <Accordion />
            </div>
            <div className='flex justify-center items-center mt-16 pb-[10vh]'>
                <Button
                    variant={"outlined"}
                    size={"large"}
                    onClick={handleRedirect}
                    title={t("faq.buttonText")}
                    buttonBold={t("faq.buttonBold")}
                    forwardIcon
                />
            </div>
        </div>
    );
};

// @ts-expect-error //lorem lorem lorem
import chs from "../../../assets/images/common/chs-logo/chs.png";
import React from "react";
import {useTranslation} from "react-i18next";

export const Header = () => {
    const { t } = useTranslation();

    return (
        <header className="text-white text-center leading-6 mt-18 md:mt-28 lg:mt-38 flex flex-col items-center">
            <img src={chs} alt="Logo" className="w-[80px] cursor-pointer mb-8" />
            <div className="mb-[20px] leading-primary text-heading-6 md:text-heading-5 font-montserrat font-semibold md:max-w-120 lg:max-w-175">
                {t("benefits.headerFirstLine")} {t("benefits.headerSecondLine")} <u><a target="_blank" href="https://challengesoft.tech">{t("benefits.headerSecondLineUnderlinedWord")}</a></u>
            </div>
            <div className="text-primary-gray-200 md:max-w-120 font-montserrat font-normal leading-normal mb-8">
                {t("benefits.descriptionFirstLine")} {t("benefits.descriptionSecondLine")}
            </div>
        </header>
    );
};
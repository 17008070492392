import React, {useEffect} from 'react';
import ScrollMagic from 'scrollmagic';
import {useTranslation} from "react-i18next";
import "../../benefits/components/strips/strips.css"

export const CitiesMarquee: React.FC = () => {
    useEffect(() => {
        const sm = new ScrollMagic.Controller({refreshInterval: 0});
        const citiesMarquee = document.querySelector('.citiesMarquee') as HTMLElement;

        const scene = new ScrollMagic.Scene({
            triggerElement: citiesMarquee,
            triggerHook: 'onEnter',
            offset: -200,
            duration: window.innerHeight + citiesMarquee.offsetWidth * 1.8,
        });

        // @ts-expect-error //lorem lorem lorem
        scene.on('progress', (event: { progress: number }) => {
            const {progress} = event;
            const amount = -citiesMarquee.scrollWidth;
            const startingOffset = -citiesMarquee.offsetWidth / 4;
            citiesMarquee.style.transform = `translate3d(0, 0, 0) translateX(${-amount * progress + startingOffset}px)`;
        });

        scene.addTo(sm);

        return () => {
            sm.destroy(true);
        };
    }, []);

    const {t} = useTranslation();

    return (
        <div className='text-white my-18 md:mt-20 lg:my-30'>
            <section>
                <div className="citiesMarquee-wrapper">
                    <div className="citiesMarquee" style={{display: 'flex'}}>

                        <div className="cities-Marquee-tag">{t("perks.citiesStrip.germany")}</div>
                        <div className="Marquee-dot w-[10px] h-[10px] rounded-2xl bg-primary-accent-error"></div>

                        <div className="cities-Marquee-tag">{t("perks.citiesStrip.usa")}</div>
                        <div className="Marquee-dot w-[10px] h-[10px] rounded-2xl bg-primary-accent-error"></div>

                        <div className="cities-Marquee-tag">{t("perks.citiesStrip.ukraine")}</div>
                        <div className="Marquee-dot w-[10px] h-[10px] rounded-2xl bg-primary-accent-error"></div>

                        <div className="cities-Marquee-tag"><span>{t("perks.citiesStrip.telAviv")}</span></div>
                        <div className="Marquee-dot w-[10px] h-[10px] rounded-2xl bg-primary-accent-error"></div>

                        <div className="cities-Marquee-tag">{t("perks.citiesStrip.germany")}</div>
                        <div className="Marquee-dot w-[10px] h-[10px] rounded-2xl bg-primary-accent-error"></div>

                        <div className="cities-Marquee-tag">{t("perks.citiesStrip.usa")}</div>
                        <div className="Marquee-dot w-[10px] h-[10px] rounded-2xl bg-primary-accent-error"></div>

                        <div className="cities-Marquee-tag">{t("perks.citiesStrip.ukraine")}</div>
                        <div className="Marquee-dot w-[10px] h-[10px] rounded-2xl bg-primary-accent-error"></div>

                        <div className="cities-Marquee-tag"><span>{t("perks.citiesStrip.telAviv")}</span></div>
                        <div className="Marquee-dot w-[10px] h-[10px] rounded-2xl bg-primary-accent-error"></div>

                        <div className="cities-Marquee-tag">{t("perks.citiesStrip.germany")}</div>
                        <div className="Marquee-dot w-[10px] h-[10px] rounded-2xl bg-primary-accent-error"></div>

                        <div className="cities-Marquee-tag">{t("perks.citiesStrip.usa")}</div>
                        <div className="Marquee-dot w-[10px] h-[10px] rounded-2xl bg-primary-accent-error"></div>

                        <div className="cities-Marquee-tag">{t("perks.citiesStrip.ukraine")}</div>
                        <div className="Marquee-dot w-[10px] h-[10px] rounded-2xl bg-primary-accent-error"></div>

                        <div className="cities-Marquee-tag"><span>{t("perks.citiesStrip.telAviv")}</span></div>
                        <div className="Marquee-dot w-[10px] h-[10px] rounded-2xl bg-primary-accent-error"></div>

                        <div className="cities-Marquee-tag">{t("perks.citiesStrip.germany")}</div>
                        <div className="Marquee-dot w-[10px] h-[10px] rounded-2xl bg-primary-accent-error"></div>

                        <div className="cities-Marquee-tag">{t("perks.citiesStrip.usa")}</div>
                        <div className="Marquee-dot w-[10px] h-[10px] rounded-2xl bg-primary-accent-error"></div>

                        <div className="cities-Marquee-tag">{t("perks.citiesStrip.ukraine")}</div>
                        <div className="Marquee-dot w-[10px] h-[10px] rounded-2xl bg-primary-accent-error"></div>

                        <div className="cities-Marquee-tag"><span>{t("perks.citiesStrip.telAviv")}</span></div>
                        <div className="Marquee-dot w-[10px] h-[10px] rounded-2xl bg-primary-accent-error"></div>

                        <div className="cities-Marquee-tag">{t("perks.citiesStrip.germany")}</div>
                        <div className="Marquee-dot w-[10px] h-[10px] rounded-2xl bg-primary-accent-error"></div>

                        <div className="cities-Marquee-tag">{t("perks.citiesStrip.usa")}</div>
                        <div className="Marquee-dot w-[10px] h-[10px] rounded-2xl bg-primary-accent-error"></div>

                        <div className="cities-Marquee-tag">{t("perks.citiesStrip.ukraine")}</div>
                        <div className="Marquee-dot w-[10px] h-[10px] rounded-2xl bg-primary-accent-error"></div>

                        <div className="cities-Marquee-tag"><span>{t("perks.citiesStrip.telAviv")}</span></div>
                        <div className="Marquee-dot w-[10px] h-[10px] rounded-2xl bg-primary-accent-error"></div>

                        <div className="cities-Marquee-tag">{t("perks.citiesStrip.germany")}</div>
                        <div className="Marquee-dot w-[10px] h-[10px] rounded-2xl bg-primary-accent-error"></div>

                        <div className="cities-Marquee-tag">{t("perks.citiesStrip.usa")}</div>
                        <div className="Marquee-dot w-[10px] h-[10px] rounded-2xl bg-primary-accent-error"></div>

                        <div className="cities-Marquee-tag">{t("perks.citiesStrip.ukraine")}</div>
                        <div className="Marquee-dot w-[10px] h-[10px] rounded-2xl bg-primary-accent-error"></div>

                        <div className="cities-Marquee-tag"><span>{t("perks.citiesStrip.telAviv")}</span></div>
                        <div className="Marquee-dot w-[10px] h-[10px] rounded-2xl bg-primary-accent-error"></div>

                        <div className="cities-Marquee-tag"><span>{t("perks.citiesStrip.telAviv")}</span></div>
                        <div className="Marquee-dot w-[10px] h-[10px] rounded-2xl bg-primary-accent-error"></div>

                        <div className="cities-Marquee-tag">{t("perks.citiesStrip.germany")}</div>
                        <div className="Marquee-dot w-[10px] h-[10px] rounded-2xl bg-primary-accent-error"></div>

                        <div className="cities-Marquee-tag">{t("perks.citiesStrip.usa")}</div>
                        <div className="Marquee-dot w-[10px] h-[10px] rounded-2xl bg-primary-accent-error"></div>

                        <div className="cities-Marquee-tag">{t("perks.citiesStrip.ukraine")}</div>
                        <div className="Marquee-dot w-[10px] h-[10px] rounded-2xl bg-primary-accent-error"></div>

                    </div>
                </div>
            </section>
        </div>
    );
};
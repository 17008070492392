import React from "react";

interface HeaderNavButtonProps {
    title: string;
    link: string;
    isActive: boolean;
    setActiveLink: React.Dispatch<React.SetStateAction<string>>;
    toggleMenu?: () => void;
}

export const HeaderNavButton: React.FC<HeaderNavButtonProps> = ({
    title,
    link,
    isActive,
    setActiveLink,
    toggleMenu
}) => {
    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        setActiveLink(link);

        if (link.startsWith("/#")) {
            // Scroll to section
            const targetElement = document.querySelector(link.replace("/#", "#"));
            if (targetElement) {
                targetElement.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                });
                // Update the URL without reloading
                window.history.pushState(null, "", link);
            }
        } else {
            // Navigate to the link
            window.location.href = link;
        }

        setTimeout(() => { if (toggleMenu) toggleMenu() }, 1600)
    };

    return (
        <a
            className={`w-fit relative text-white underline-nav-item text-sm p-1 mr-4 font-lato cursor-pointer ${isActive ? "active" : ""
                }`}
            onClick={handleClick}
            href={link}
        >
            {title}
        </a>
    );
};

import React from "react";
import { logos } from "../../../../assets/images/logos/logos";

interface TrustedLogoProps {
  logoKey: keyof typeof logos;
}

export const Logo: React.FC<TrustedLogoProps> = ({ logoKey }) => {
  const [isHovered, setIsHovered] = React.useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };

  return (
      <div className="flex flex-row flex-wrap justify-center items-center  ">
        <img
            src={isHovered ? logos[logoKey].color : logos[logoKey].bw}
            alt={`Logo - ${logoKey}`}
            onMouseEnter={handleHover}
            onMouseLeave={handleLeave}
            className={`transition-opacity ${
              isHovered ? "opacity-100" : "opacity-70"} h-[54px] object-contain`}
        />
      </div>
  );
}

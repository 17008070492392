import React from 'react';
import {useTranslation} from "react-i18next";

export const Header = () => {

    const { t } = useTranslation();

    return (
        <header className="flex flex-col items-center">
            <div className="text-white text-heading-5 mb-4 mt-8 leading-none font-montserrat font-extrabold">
                {t("contactUs.header")}
            </div>
            <div className="text-white mb-12 max-w-158 font-montserrat">
                {t("contactUs.subHeaderFL")} {t("contactUs.subHeaderSL")}
            </div>
        </header>
    );
};
import React from 'react';
import { Header } from './components/header';
import { Block } from "./components/block";
import { useTranslation } from "react-i18next";
import { icons } from "../../assets/icons/icons";
import {CitiesMarquee} from "./components/cities-marquee";

export const Perks = () => {
    const { t } = useTranslation();

    return (
        <div id='benefits' className="flex flex-col items-center justify-center">
            <Header />
            <div className="gap-12 md:gap-18 justify-center md:grid md:grid-cols-2 flex flex-col">
                <Block
                    icon={icons.perks.block1}
                    header={t("perks.blocks.block1.header")}
                    description={t("perks.blocks.block1.description")}
                />
                <Block
                    icon={icons.perks.block2}
                    header={t("perks.blocks.block2.header")}
                    description={t("perks.blocks.block2.description")}
                />
                <Block
                    icon={icons.perks.block3}
                    header={t("perks.blocks.block3.header")}
                    description={t("perks.blocks.block3.description")}
                />
                <Block
                    icon={icons.perks.block4}
                    header={t("perks.blocks.block4.header")}
                    description={t("perks.blocks.block4.description")}
                />
            </div>
            <CitiesMarquee/>
        </div>
    );
}

import React, { useEffect } from 'react';
import ScrollMagic from 'scrollmagic';
import { useTranslation } from "react-i18next";
import "../strips.css"

export const FadedMarqueeComponent: React.FC = () => {
    useEffect(() => {
        const sm = new ScrollMagic.Controller({ refreshInterval: 0 });
        const fadedMarquee = document.querySelector('.fadedMarquee') as HTMLElement;

        const FadedScene = new ScrollMagic.Scene({
            triggerElement: fadedMarquee,
            triggerHook: 'onEnter',
            offset: 0,
            duration: window.innerHeight + fadedMarquee.scrollWidth * 1.9,
        });

        // @ts-expect-error //lorem lorem lorem
        FadedScene.on('progress', (event: { progress: number }) => {
            const {progress} = event;
            const amount = -fadedMarquee.scrollWidth;
            const startingOffset = -fadedMarquee.offsetWidth / 3;
            fadedMarquee.style.transform = `translate3d(0, 0, 0) translateX(${-amount * progress + startingOffset}px)`;
        });

        FadedScene.addTo(sm);

        return () => {
            sm.destroy(true);
        };
    }, []);

    const { t } = useTranslation();

    const marqueeItems = [
        { label: t("benefits.strips.consultation"), color: "bg-primary-accent-info" },
        { label: t("benefits.strips.server"), color: "bg-primary-accent-error" },
        { label: t("benefits.strips.api"), color: "bg-primary-accent-3" },
        { label: t("benefits.strips.appDesign"), color: "bg-primary-accent-info" },
        { label: t("benefits.strips.development"), color: "bg-primary-accent-3" },
        { label: t("benefits.strips.webDesign"), color: "bg-primary-accent-error" },
        { label: t("benefits.strips.seo"), color: "bg-primary-accent-info" },
        { label: t("benefits.strips.mobile"), color: "bg-primary-accent-3" },
        { label: t("benefits.strips.consultation"), color: "bg-primary-accent-info" },
        { label: t("benefits.strips.server"), color: "bg-primary-accent-error" },
        { label: t("benefits.strips.api"), color: "bg-primary-accent-3" },
        { label: t("benefits.strips.appDesign"), color: "bg-primary-accent-info" },
        { label: t("benefits.strips.development"), color: "bg-primary-accent-3" },
        { label: t("benefits.strips.webDesign"), color: "bg-primary-accent-error" },
        { label: t("benefits.strips.seo"), color: "bg-primary-accent-info" },
        { label: t("benefits.strips.mobile"), color: "bg-primary-accent-3" },
        { label: t("benefits.strips.consultation"), color: "bg-primary-accent-info" },
        { label: t("benefits.strips.server"), color: "bg-primary-accent-error" },
        { label: t("benefits.strips.api"), color: "bg-primary-accent-3" },
        { label: t("benefits.strips.appDesign"), color: "bg-primary-accent-info" },
        { label: t("benefits.strips.development"), color: "bg-primary-accent-3" },
        { label: t("benefits.strips.webDesign"), color: "bg-primary-accent-error" },
        { label: t("benefits.strips.seo"), color: "bg-primary-accent-info" },
        { label: t("benefits.strips.mobile"), color: "bg-primary-accent-3" },
        { label: t("benefits.strips.mobile"), color: "bg-primary-accent-3" },
        { label: t("benefits.strips.consultation"), color: "bg-primary-accent-info" },
        { label: t("benefits.strips.server"), color: "bg-primary-accent-error" },
        { label: t("benefits.strips.api"), color: "bg-primary-accent-3" },
        { label: t("benefits.strips.appDesign"), color: "bg-primary-accent-info" },
        { label: t("benefits.strips.development"), color: "bg-primary-accent-3" },
        { label: t("benefits.strips.webDesign"), color: "bg-primary-accent-error" },
        { label: t("benefits.strips.seo"), color: "bg-primary-accent-info" }
    ];

    return (
        <div className='text-white'>
            <section>
                <div className="faded-marquee-wrapper">
                    <div className="fadedMarquee" style={{ display: 'flex' }}>
                        {marqueeItems.map((item, index) => (
                            <React.Fragment key={index}>
                                <div className="Marquee-tag">{item.label}</div>
                                <div className={`Marquee-dot w-[20px] h-[20px] rounded-2xl ${item.color}`}></div>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

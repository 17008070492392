import React from "react";
import {icons} from "../../../assets/icons/icons";
import {useTranslation} from "react-i18next";

interface HeroHeaderProps {
    onPlayButtonClick: () => void;
    fadeOut: boolean;
}

export const HeroHeader: React.FC<HeroHeaderProps> = ({onPlayButtonClick, fadeOut}) => {
    const {t} = useTranslation();
    return (
        <div
            className={`leading-none text-white text-heading-1 ${fadeOut ? "fade-out" : ""}  
             inset-0 text-white flex flex-col content-center items-center z-[3] gap-y-5
             xl:text-heading-1
             lg:text-heading-2
             md:text-heading-3
             sm:text-heading-4
             text-heading-3
            `}>
            <div className="flex flex-col text-center sm:flex-row mb-[20px] gap-[2vw]">
                {/*flfw = first line first word */}
                    <span>{t("hero.flfw")}</span> {/*unique*/}
                    <span className="text-primary-purple-600">{t("hero.flsw")}</span> {/*branding*/}
                </div>
            <div className="flex flex-col sm:flex-row items-center gap-[24px]">
                <span>{t("hero.slfw")}</span> {/*focused*/}
                <img
                    className="cursor-pointer
                    xl:w-[200px] xl:h-[200px]
                    lg:w-[120px] lg:h-[120px]
                    md:w-[100px] md:h-[100px]
                    sm:w-[80px] sm:h-[80px]
                    w-[70px] h-[70px]
                    "
                    style={{
                        transition: 'transform 200ms ease',
                        transform: fadeOut ? 'scale(0)' : 'scale(1)',
                    }}
                    src={icons.button.play}
                    alt={"play"}
                    onClick={onPlayButtonClick}
                />
                <span>{t("hero.slsw")}</span> {/*on your*/}
            </div>
            <div className="flex flex-row">
                <span>{t("hero.tlfw")}</span> {/*business*/}
            </div>
            <div className="flex flex-row sm:hidden"> {/* to keep the video background centered */}
                <div style={{ height: 30 }}></div>
            </div>
        </div>
    );
};
import React from "react";

export const CardGradientLine = ({color = false}) => {
    const backgroundStyle = color
        ? {
            background:
                'linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0)) 50% 50%/calc(100% - 2px) calc(100% - 2px) no-repeat, radial-gradient(#8544AE 0%, #391743 100%, #8544ae 100%)',
        }
        : {
            background:
                'radial-gradient(125.6% 517.94% at -11.43% 50%, rgba(14, 14, 14, 0.317834) 0%, #FFDDE1 49.7%, rgba(32, 32, 32, 0.29) 100%)'
        };

    return (
        <div
            style={{...backgroundStyle}}
            className='h-[1px] w-[100%] self-center'
        />
    );
};
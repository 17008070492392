import React from 'react';
import { bgImages } from "../../assets/images/bg-images";
import {artBigLogos} from "../../assets/images/common/art-logo-variants/art-big-logos";

export const ArtChallenge = () => {
    return (
        <div style={{
            backgroundImage: `url(${bgImages.artChallenge})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <div className='flex flex-col py-6'>
                <img className='w-[412px], h-[250px]' src={artBigLogos.outline} alt="Art Challenge"/>
                <img className='w-[412px], h-[250px]' src={artBigLogos.filled} alt="Art Challenge"/>
                <img className='w-[412px], h-[250px]' src={artBigLogos.outline} alt="Art Challenge"/>
            </div>
        </div>
    );
};

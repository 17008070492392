import React, {useState} from "react";
import {Panel} from "./panel";
import {useTranslation} from "react-i18next";

export const Accordion = () => {
    const [activeTab, setActiveTab] = useState<number>(1);
    const { t } = useTranslation();

    const panelData = [
        { label: t("faq.panels.panel1.label"), content: t("faq.panels.panel1.content"), index: 1 },
        { label: t("faq.panels.panel2.label"), content: t("faq.panels.panel2.content"), index: 2 },
        { label: t("faq.panels.panel3.label"), content: t("faq.panels.panel3.content"), index: 3 },
        { label: t("faq.panels.panel4.label"), content: t("faq.panels.panel4.content"), index: 4 },
        { label: t("faq.panels.panel5.label"), content: t("faq.panels.panel5.content"), index: 5 },
        { label: t("faq.panels.panel6.label"), content: t("faq.panels.panel6.content"), index: 6 }
    ];

    return (
        <div className="flex flex-col items-center">
            <div className="w-full md:w-[770px] w-[90%]">
                {panelData.map((panel, index) => (
                    <Panel
                        key={index}
                        label={panel.label}
                        content={panel.content}
                        activeTab={activeTab}
                        index={panel.index}
                        activateTab={(index: number) => setActiveTab(index)}
                    />
                ))}
            </div>
        </div>
    )
}